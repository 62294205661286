<template>
  <div class="p-1">
    <div class="flex justify-between items-center mb-2 flex-wrap">
      <h3 class="text-2xl">Secciones</h3>
      <a-button :to="{ name: 'create_section' }" v-if="user.isAdmin" class="w-full md:w-auto">
        Crear sección
      </a-button>
    </div>
    <a-table
      :columns="columns"
      :source="pagination.data"
      :loading="loading || loadingPeriods || loadingSpec"
      toolbar-column-filter
      :pagination="pagination"
      @paginate="search({ page: $event })"
      @toolbar(refresh)="search({ page: 1 })"
      @delayed-filter:letter_code="search({ search: $event, page: 1 })"
      @delayed-filter:semester_number="search({ semester_number: $event, page: 1 })">
<!--      @toolbar(search)="search({ search: $event, page: 1 })">-->

      <template #td(actions)="{ item }">
        <a-dropdown>
          <template #menu>
            <a-dropdown-item :to="{ name: 'section', params: { section_id: item.id } }">
              Ver sección
            </a-dropdown-item>
            <a-dropdown-item :to="{ name: 'create_section', query: { update: item.id } }" v-if="user.isAdmin">
              Actualizar
            </a-dropdown-item>
            <a-dropdown-item @click="deleteSemester(item.id)"  v-if="user.isAdmin">
              Eliminar
            </a-dropdown-item>
          </template>
        </a-dropdown>
      </template>

      <template #filter(specialization.title)>
        <v-select
          @clear="search({ page: 1 })"
          :disabled="loading || loadingPeriods || loadingSpec"
          :clearable="user.isAdmin ? true : false"
          :options="mappedSpecialities"
          :reduce="$0 => $0.value"
          placeholder="Especialidad"
          class="w-56"
          v-model="query.specialization"/>
      </template>

      <template #toolbar>
        <v-select
          :clearable="false"
          :disabled="loading || loadingPeriods || loadingSpec"
          :options="mappedPeriods"
          :loading="loadingPeriods"
          :reduce="$0 => $0.value"
          placeholder="Período"
          class="w-48"
          v-model="query.period"/>
      </template>

      <template #empty>
        <h1 v-if="!period" class="text-center p-8 text-2xl font-medium">
          No hay datos para mostrar
        </h1>
      </template>
    </a-table>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
  data: () => ({
    limit: 10,
    query: {
      limit: 10,
      with: 'period,specialization,semester,courses',
      search: '',
      specialization: '',
      semester: '',
      sort_by: 'letter_code',
      period: null
    },
    specializations: [],
    period: null,
    timeout: null,
    periods: [],
    loadingSpec: false,
    loadingPeriods: false
  }),
  computed: {
    ...mapState({
      pagination: state => state.sections.pagination,
      spec: state => state.specializations.pagination,
      coordSpec: state => state.teachers.all,
      loading: state => state.sections.loading,
      user: state => state.session.user
    }),
    mappedPeriods() {
      return this.periods.map($0 => ({ label: `${$0.year}-${$0.number}`, value: $0.id }))
    },
    mappedSpecialities() {
      return this.user.isAdmin ? this.spec.data.map($0 => ({ label: $0.title, value: $0.id })) : this.specializations.map($0 => ({ label: $0.title, value: $0.id }))
    },
    columns() {
      return [
        { title: 'Sección', key: 'letter_code', filter: true, preventLocalFilter: true, display: true },
        { title: 'Varones', key: 'males', mutate: ({ item }) => `${item.males_assigned} / ${item.males_limit}`, display: true },
        { title: 'Hembras', key: 'females', mutate: ({ item }) => `${item.females_assigned} / ${item.females_limit}`, display: true },
        { title: 'Especialidad', key: 'specialization.title', filter: true, preventLocalFilter: true, display: true },
        { title: 'Período', key: 'period', mutate: ({ key }) => `${key.year}-${key.number}`, display: this.user.isAdmin ? false : !this.query.period },
        { title: 'Semestre', key: 'semester.number', filter: true, preventLocalFilter: true, display: true },
        { title: '¿Completa?', key: 'filled', mutate: ({ key }) => key ? 'Si' : 'No',display: true },
        { title: 'Acciones', slot: 'actions', alignment: 'right', display: !this.user.isStudent && !this.user.isLowAdmin, columnFilterable: false }
      ].filter($0 => $0.display)
    }
  },
  methods: {
    ...mapActions({
      fetchSections: 'sections/fetchAll',
      fetchSpecializations: 'specializations/fetchSpecializations',
      deleteSemester: 'sections/delete',
      fetchCoordSpecializations: 'teachers/coordinateSpecializations'
    }),
    ...mapMutations({
      setNavbarTitle: 'SET_NAVBAR_TITLE'
    }),
    search(query) {
      if(this.user.isAdmin){
        this.fetchSections({ ...this.query, ...query })
      } else{
        this.loadingSpec = true
        if(this.query.specialization === 'todos'){
          const idsArray = this.specializations.find(ids => ids.id === 'todos')
          this.fetchSections({ ...this.query, ...query, specialization: idsArray.code }).finally(() => this.loadingSpec = false)
        } else{
          this.fetchSections({ ...this.query, ...query }).finally(() => this.loadingSpec = false)
        }
      }
    },
    fetchPeriods(query) {
      this.loadingPeriods = !this.loadingPeriods
      this.$repository.periods
        .index(query)
        .then(({ data }) => {
          this.periods = data
          this.user.isAdmin ? this.query.period = this.periods.find((value) => value.enabled ?? value).id : null
        })
        .finally(() => this.loadingPeriods = !this.loadingPeriods)
    },
    fetchSpec () {
      if(this.user.isCoordinator){
        this.fetchCoordSpecializations({ id: this.user.teacher.id })
          .finally(() => {

            const todos = { id: 'todos', title: 'TODOS', code: this.coordSpec.map(ids => ids.id) }
            const specializationsWithTodos = this.coordSpec
            specializationsWithTodos.length === 1 ? null : specializationsWithTodos.push(todos)
            const filteredData = specializationsWithTodos.sort((a, b) => a.id - b.id)
            const lastIndex = filteredData.length - 1
            this.query.specialization = filteredData[lastIndex].id
            this.specializations = filteredData
          })
      }else{
        this.fetchSpecializations()
          .finally(() => {
            this.specializations = this.spec.data.map($0 => ({ label: $0.title, value: $0.id }))
          })
      }
    }
  },
  watch: {
    'query.period'(val) {
      if (val) {
        if(this.user.isAdmin){
          this.fetchSections({ ...this.query, page: this.$route.query.page || 1, period: val })
        } else {
          this.loadingSpec = true
          if(this.query.specialization === 'todos'){
            const idsArray = this.specializations.find(ids => ids.id === 'todos')
            this.fetchSections({ ...this.query, specialization: idsArray.code, page: this.$route.query.page || 1, period: val }).finally(() => this.loadingSpec = false)
          } else{
            this.fetchSections({ ...this.query, page: this.$route.query.page || 1, period: val }).finally(() => this.loadingSpec = false)
          }
        }
      }
    },
    'query.specialization'(val) {
        if(val === 'todos'){
          this.loadingSpec = true
          const idsArray = this.specializations.find(ids => ids.id === val)
          this.fetchSections({ ...this.query, specialization: idsArray.code, page: 1 }).finally(() => this.loadingSpec = false)
        }else{
          this.loadingSpec = true
          this.fetchSections({ ...this.query, page: 1 }).finally(() => this.loadingSpec = false)
        }
    }
  },
  mounted() {
    this.user.isCoordinator ? this.loadingSpec = true : this.loadingSpec = false
    this.fetchPeriods({ limit: 0, sort_by: 'year,asc' })
    this.fetchSpec()
  }
}
</script>
